/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   1 = DegreeCelsius  2 = DegreeDelisle  3 = DegreeFahrenheit  4 = DegreeNewton  5 = DegreeRankine  6 = DegreeReaumur  7 = DegreeRoemer  8 = Kelvin  9 = MillidegreeCelsius  10 = SolarTemperature
 */
export enum TemperatureUnit {
  DegreeCelsius = 1,
  DegreeDelisle = 2,
  DegreeFahrenheit = 3,
  DegreeNewton = 4,
  DegreeRankine = 5,
  DegreeReaumur = 6,
  DegreeRoemer = 7,
  Kelvin = 8,
  MillidegreeCelsius = 9,
  SolarTemperature = 10,
}
