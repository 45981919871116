/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of complex object serialized for the summary  0 = Undefined  1 = Text  2 = Number  3 = Boolean  4 = Date  5 = UserGroups  6 = Locations  7 = Calendar  8 = Cargo  9 = Vehicle  10 = PriceRange  11 = RecurringShipment  12 = CO2Data  13 = LaneRequestVehicle  14 = Currency  15 = LaneBookingSelectedCarrier
 */
export enum SummaryDataType {
  Undefined = 0,
  Text = 1,
  Number = 2,
  Boolean = 3,
  Date = 4,
  UserGroups = 5,
  Locations = 6,
  Calendar = 7,
  Cargo = 8,
  Vehicle = 9,
  PriceRange = 10,
  RecurringShipment = 11,
  CO2Data = 12,
  LaneRequestVehicle = 13,
  Currency = 14,
  LaneBookingSelectedCarrier = 15,
}
