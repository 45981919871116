/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = WhiteList  2 = ManagedTransportCarrier  3 = CTMSLinkedGroup
 */
export enum GroupRelationType {
  Undefined = 0,
  WhiteList = 1,
  ManagedTransportCarrier = 2,
  CTMSLinkedGroup = 3,
}
