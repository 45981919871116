/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = None  1 = Europe  2 = NorthAmerica  16 = Australia
 */
export enum AreaOfOperation {
  None = 0,
  Europe = 1,
  NorthAmerica = 2,
  Australia = 16,
}
