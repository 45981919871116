/**
 * Sequence API
 * <p>The Sequence API is used to manage Sequences</p>
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@chaincode.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *   0 = Undefined  1 = General  2 = Enterprise  4 = WarehouseCustomer  8 = Location  16 = Carrier  32 = Shipper  64 = FinanceGeneral  128 = FinanceCustomer  256 = FinanceSupplier  512 = Internal  1024 = WarehouseSupplier  2048 = CarbonSupplier  4096 = ManagedTransShipper
 */
export enum DomainGroupType {
  Undefined = 0,
  General = 1,
  Enterprise = 2,
  WarehouseCustomer = 4,
  Location = 8,
  Carrier = 16,
  Shipper = 32,
  FinanceGeneral = 64,
  FinanceCustomer = 128,
  FinanceSupplier = 256,
  Internal = 512,
  WarehouseSupplier = 1024,
  CarbonSupplier = 2048,
  ManagedTransShipper = 4096,
}
